@font-face {
  font-family: "RobotoRegular";
  src: local("RobotoRegular"),
   url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoLight";
  src: local("RobotoLight"),
   url("./fonts/Roboto/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoBold";
  src: local("RobotoBold"),
   url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
